import { createStore, Store, useStore as baseUseStore } from 'vuex'
import { InjectionKey } from 'vue'
import { IUserProfileResponse, ILayoutDataModel } from '@/types'
import { getUserProfileApi } from '@/library/axios/api'
import { isObject } from 'lodash'
import { layout_6k_A1 } from '@/models/um-layouts'

export interface IState {
  useProfile: IUserProfileResponse | null
  layoutData: ILayoutDataModel
}

export const storeKey: InjectionKey<Store<IState>> = Symbol()
const storedLayoutData = localStorage.getItem('layoutData')
const savedLayoutData = storedLayoutData
  ? JSON.parse(storedLayoutData)
  : layout_6k_A1

export const store = createStore<IState>({
  state: {
    useProfile: null,
    layoutData: savedLayoutData
  },
  mutations: {
    SetUserProfile(state, value: IUserProfileResponse | null) {
      state.useProfile = value
    },
    SetLayoutData(state, value: ILayoutDataModel) {
      state.layoutData = value
      localStorage.setItem('layoutData', JSON.stringify(value))
    }
  },
  actions: {
    getUserProfile: async (context) => {
      const data: IUserProfileResponse = await getUserProfileApi()
      if (isObject(data)) {
        context.commit('SetUserProfile', data)
      } else {
        context.commit('SetUserProfile', null)
      }
    },
    saveLayoutData: async (context, data) => {
      context.commit('SetLayoutData', data)
    }
  },
  modules: {}
})

export function useStore() {
  return baseUseStore(storeKey)
}
