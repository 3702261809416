
import { defineComponent, computed } from 'vue'
import { Right, User } from '@element-plus/icons-vue'
import { useRouter } from 'vue-router'
import { routeList } from '@/router'
import { identityGroup } from '@/models'
import { useStore } from '@/store'
import { isObject } from 'lodash'

interface ILinkItem {
  name: string
  routeName: string
}
export default defineComponent({
  components: { Right, User },
  name: 'Home',
  setup() {
    const router = useRouter()
    const store = useStore()
    const linkList: ILinkItem[] = [
      {
        name: '客製雨傘',
        routeName: routeList['custom-umbrella']
      },
      {
        name: '我的設計',
        routeName: routeList['my-design']
      },
      {
        name: '個人圖庫',
        routeName: routeList['photo-storage']
      }
      // {
      //   name: '權限管理',
      //   routeName: routeList.permission
      // }
    ]
    const userProfile = computed(() => store.state.useProfile)

    const useName = computed(() => {
      if (isObject(userProfile.value)) {
        if (userProfile.value.groups.includes(identityGroup.admin)) {
          return '管理員'
        }
        if (userProfile.value.groups.includes(identityGroup.sales)) {
          return '業務'
        }
      }
      return ''
    })

    const handleClick = (routeName: string) => {
      router.push({ name: routeName })
    }

    const userNickName = computed(() => store.state.useProfile?.nickname)

    const handleLogout = () => {
      localStorage.removeItem('accessToken')
      localStorage.removeItem('refreshToken')
      localStorage.removeItem('accessTokenExp')
      localStorage.removeItem('refreshTokenExp')
      router.push({ name: routeList.login })
    }

    return {
      useName,
      userProfile,
      linkList,
      handleClick,
      routeList,
      identityGroup,
      userNickName,
      handleLogout
    }
  }
})
