
import { defineComponent } from 'vue'
import TheHeader from '@/components/project/Header.vue'
export default defineComponent({
  name: 'LayoutDefault',
  components: { TheHeader },
  setup() {
    return {}
  }
})
