import { createApp } from 'vue'
import App from './App.vue'
import ElementPlusPlugin from './plugins/element-plus'
import Layouts from './plugins/layouts'
// import Components from './plugins/components'
import './registerServiceWorker'
import router from './router'
import { store, storeKey } from './store'
// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
// const vh = window.innerHeight * 0.01
// Then we set the value in the --vh custom property to the root of the document
// document.documentElement.style.setProperty('--vh', `${vh}px`)
createApp(App)
  .use(store, storeKey)
  .use(router)
  .use(ElementPlusPlugin)
  .use(Layouts)
  // .use(Components)
  .mount('#app')
