import { IEnumToArray } from './constants'

export enum identityGroup {
  admin = 1,
  sales = 2
}

export function EnumToArray(o: any): IEnumToArray[] {
  return Object.entries(o)
    .filter((x) => typeof x[1] === 'number')
    .map((value) => ({ id: value[1] as number, name: value[0] }))
}

export const jiraTicketOptions: IEnumToArray[] = EnumToArray(identityGroup)
