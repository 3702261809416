import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { store } from '@/store'
import { isString, isNil } from 'lodash'
import HomeView from '../views/home/index.vue'

export const routeList = {
  home: 'home',
  login: 'login',
  'custom-umbrella': 'custom-umbrella',
  ['custom-umbrella_select-type']: 'custom-umbrella_select-type',
  ['custom-umbrella_select-layout']: 'custom-umbrella_select-layout',
  ['generate-2d-image']: 'generate-2d-image',
  ['3d-viewer']: '3d-viewer',
  ['sharing-3d-viewer']: 'sharing-3d-viewer',
  ['custom-umbrella_design-umbrella']: 'editor',
  'my-design': 'my-design',
  'my-design_my-design': 'my-design_my-design',
  'photo-storage': 'photo-storage',
  'photo-storage_management': 'photo-storage_management',
  permission: 'permission',
  ['permission_library']: 'permission_library',
  ['permission_management']: 'permission_management',
  ['permission_sales-information']: 'permission_sales-information'
}

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: routeList.home,
    component: HomeView,
    alias: '/home',
    meta: {
      layout: 'LayoutNormal',
      isVerifyIdentity: true
    }
  },
  {
    path: '/login',
    name: routeList.login,
    component: () => import('../views/login/index.vue'),
    meta: {
      layout: 'LayoutNormal',
      isVerifyIdentity: false
    }
  },
  {
    path: '/custom-umbrella',
    name: routeList['custom-umbrella'],
    meta: {
      layout: 'LayoutDefault',
      isVerifyIdentity: true
    },
    redirect: () => {
      return {
        name: routeList['custom-umbrella_select-type']
      }
    },
    children: [
      {
        path: 'type',
        name: routeList['custom-umbrella_select-type'],
        component: () =>
          import('../views/custom-umbrella/select-type/index.vue'),
        meta: {
          layout: 'LayoutDefault'
        }
      },
      {
        path: 'layout',
        name: routeList['custom-umbrella_select-layout'],
        component: () =>
          import('../views/custom-umbrella/select-layout/index.vue'),
        meta: {
          layout: 'LayoutDefault'
        }
      },
      {
        path: 'generate-2d-image',
        name: routeList['generate-2d-image'],
        component: () =>
          import('../views/custom-umbrella/generate-2d-image/index.vue'),
        meta: {
          layout: 'LayoutNormal'
        }
      },
      {
        path: 'editor',
        name: routeList['custom-umbrella_design-umbrella'],
        component: () =>
          import('../views/custom-umbrella/design-umbrella/index.vue'),
        meta: {
          layout: 'LayoutNormal'
        }
      },
      {
        path: '3d-viewer',
        name: routeList['3d-viewer'],
        component: () => import('../views/custom-umbrella/3d-viewer/index.vue'),
        meta: {
          layout: 'LayoutNormal'
        }
      }
    ]
  },
  {
    path: '/sharing-3d-viewer/:token',
    name: routeList['sharing-3d-viewer'],
    component: () =>
      import('../views/custom-umbrella/sharing-3d-viewer/index.vue'),
    meta: {
      layout: 'LayoutNormal'
    }
  },
  {
    path: '/my-design',
    name: routeList['my-design'],
    component: () => import('../views/my-design/index.vue'),
    meta: {
      layout: 'LayoutDefault',
      isVerifyIdentity: true
    },
    redirect: () => {
      return {
        name: routeList['my-design_my-design']
      }
    },
    children: [
      {
        path: 'my-design',
        name: routeList['my-design_my-design'],
        component: () => import('../views/my-design/my-design/index.vue')
      }
    ]
  },
  {
    path: '/photo-storage',
    name: routeList['photo-storage'],
    component: () => import('../views/photo-storage/index.vue'),
    meta: {
      layout: 'LayoutDefault',
      isVerifyIdentity: true
    },
    redirect: () => {
      return {
        name: routeList['photo-storage_management']
      }
    },
    children: [
      {
        path: 'management',
        name: routeList['photo-storage_management'],
        component: () => import('../views/photo-storage/management/index.vue')
      }
    ]
  },
  {
    path: '/permission',
    name: routeList.permission,
    component: () => import('../views/permission/index.vue'),
    meta: {
      layout: 'LayoutDefault',
      isVerifyIdentity: true
    },
    redirect: () => {
      return {
        name: routeList.permission_library
      }
    },
    children: [
      {
        path: 'library',
        name: routeList.permission_library,
        component: () => import('../views/permission/library/index.vue')
      },
      {
        path: 'management',
        name: routeList.permission_management,
        component: () => import('../views/permission/management/index.vue')
      },
      {
        path: 'sales-information/:uuid',
        name: routeList['permission_sales-information'],
        component: () =>
          import('../views/permission/sales-information/index.vue')
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to, from, next) => {
  const { meta } = to
  const { isVerifyIdentity } = meta
  const hadToken = localStorage.getItem('accessToken')
  if (isVerifyIdentity) {
    if (isString(hadToken)) {
      if (isNil(store.state.useProfile)) {
        await store.dispatch('getUserProfile')
      }
      next()
    } else {
      next(routeList.login)
    }
  } else {
    next()
  }
})

export default router
