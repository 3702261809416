const layout_6k_A1 = {
  layoutType: '6k',
  canopy: {
    imageURL: '',
    layout: {
      numberOfSidedPolygon: 6,
      noPolygon: true,
      sides: [
        {
          id: null,
          positions: [],
          image: null,
          hitColor: '#B8BFD1',
          designs: []
        }
      ]
    },
    modelID: 'D1',
    meshName: 'Canopy'
  },
  innerCanopy: {
    imageURL: null,
    layout: {
      numberOfSidedPolygon: 6,
      noPolygon: true,
      sides: [
        {
          id: null,
          positions: [],
          image: null,
          hitColor: '#B8BFD1',
          designs: []
        }
      ]
    },
    designs: [],
    modelID: 'D1',
    meshName: 'Inner_canopy'
  },
  belt: {
    imageURL: null,
    layout: {
      numberOfSidedPolygon: 6,
      noPolygon: true,
      sides: [
        {
          id: null,
          positions: [],
          image: null,
          hitColor: '#B8BFD1',
          designs: []
        }
      ]
    },
    designs: [],
    modelID: 'D1',
    meshName: 'Belt1'
  },
  cover: {
    imageURL: null,
    layout: {
      numberOfSidedPolygon: 6,
      noPolygon: true,
      sides: [
        {
          id: null,
          positions: [],
          image: null,
          hitColor: '#B8BFD1',
          designs: []
        }
      ]
    },
    designs: [],
    modelID: 'D3',
    meshName: 'Sleeve'
  }
}

const layout_6k_A2 = {
  layoutType: '6k',
  canopy: {
    imageURL: '',
    layout: {
      numberOfSidedPolygon: 6,
      noPolygon: false,
      sides: [
        {
          id: 'A',
          positions: [1, 2, 3, 4, 5, 6],
          image: null,
          hitColor: '#5E6A8A'
        }
      ]
    },
    modelID: 'D1',
    meshName: 'Canopy'
  },
  innerCanopy: {
    imageURL: null,
    layout: {
      numberOfSidedPolygon: 6,
      noPolygon: true,
      sides: [
        {
          id: null,
          positions: [],
          image: null,
          hitColor: '#B8BFD1',
          designs: []
        }
      ]
    },
    designs: [],
    modelID: 'D1',
    meshName: 'Inner_canopy'
  },
  belt: {
    imageURL: null,
    layout: {
      numberOfSidedPolygon: 6,
      noPolygon: true,
      sides: [
        {
          id: null,
          positions: [],
          image: null,
          hitColor: '#B8BFD1',
          designs: []
        }
      ]
    },
    designs: [],
    modelID: 'D1',
    meshName: 'Belt1'
  },
  cover: {
    imageURL: null,
    layout: {
      numberOfSidedPolygon: 6,
      noPolygon: true,
      sides: [
        {
          id: null,
          positions: [],
          image: null,
          hitColor: '#B8BFD1',
          designs: []
        }
      ]
    },
    designs: [],
    modelID: 'D3',
    meshName: 'Sleeve'
  }
}

const layout_6k_A3 = {
  layoutType: '6k',
  canopy: {
    imageURL: '',
    layout: {
      numberOfSidedPolygon: 6,
      noPolygon: false,
      sides: [
        {
          id: 'A',
          positions: [1, 3, 5],
          image: null,
          hitColor: '#5E6A8A'
        },
        {
          id: 'B',
          positions: [2, 4, 6],
          image: null,
          hitColor: '#B8BFD1'
        }
      ]
    },
    modelID: 'D1',
    meshName: 'Canopy'
  },
  innerCanopy: {
    imageURL: null,
    layout: {
      numberOfSidedPolygon: 6,
      noPolygon: true,
      sides: [
        {
          id: null,
          positions: [],
          image: null,
          hitColor: '#B8BFD1',
          designs: []
        }
      ]
    },
    designs: [],
    modelID: 'D1',
    meshName: 'Inner_canopy'
  },
  belt: {
    imageURL: null,
    layout: {
      numberOfSidedPolygon: 6,
      noPolygon: true,
      sides: [
        {
          id: null,
          positions: [],
          image: null,
          hitColor: '#B8BFD1',
          designs: []
        }
      ]
    },
    designs: [],
    modelID: 'D1',
    meshName: 'Belt1'
  },
  cover: {
    imageURL: null,
    layout: {
      numberOfSidedPolygon: 6,
      noPolygon: true,
      sides: [
        {
          id: null,
          positions: [],
          image: null,
          hitColor: '#B8BFD1',
          designs: []
        }
      ]
    },
    designs: [],
    modelID: 'D3',
    meshName: 'Sleeve'
  }
}

const layout_6k_A4 = {
  layoutType: '6k',
  canopy: {
    imageURL: '',
    layout: {
      numberOfSidedPolygon: 6,
      noPolygon: false,
      sides: [
        {
          id: 'A',
          positions: [1],
          image: null,
          hitColor: '#B8BFD1'
        },
        {
          id: 'B',
          positions: [6],
          image: null,
          hitColor: '#B8BFD2'
        },
        {
          id: 'C',
          positions: [5],
          image: null,
          hitColor: '#B8BFD3'
        },
        {
          id: 'D',
          positions: [4],
          image: null,
          hitColor: '#B8BFD4'
        },
        {
          id: 'E',
          positions: [3],
          image: null,
          hitColor: '#B8BFD5'
        },
        {
          id: 'F',
          positions: [2],
          image: null,
          hitColor: '#B8BFD6'
        }
      ]
    },
    modelID: 'D1',
    meshName: 'Canopy'
  },
  innerCanopy: {
    imageURL: null,
    layout: {
      numberOfSidedPolygon: 6,
      noPolygon: true,
      sides: [
        {
          id: null,
          positions: [],
          image: null,
          hitColor: '#B8BFD1',
          designs: []
        }
      ]
    },
    designs: [],
    modelID: 'D1',
    meshName: 'Inner_canopy'
  },
  belt: {
    imageURL: null,
    layout: {
      numberOfSidedPolygon: 6,
      noPolygon: true,
      sides: [
        {
          id: null,
          positions: [],
          image: null,
          hitColor: '#B8BFD1',
          designs: []
        }
      ]
    },
    designs: [],
    modelID: 'D1',
    meshName: 'Belt1'
  },
  cover: {
    imageURL: null,
    layout: {
      numberOfSidedPolygon: 6,
      noPolygon: true,
      sides: [
        {
          id: null,
          positions: [],
          image: null,
          hitColor: '#B8BFD1',
          designs: []
        }
      ]
    },
    designs: [],
    modelID: 'D3',
    meshName: 'Sleeve'
  }
}

const layout_6k_A5 = {
  layoutType: '6k',
  canopy: {
    imageURL: '',
    layout: {
      numberOfSidedPolygon: 6,
      noPolygon: false,
      sides: [
        {
          id: 'A',
          positions: [1, 4],
          image: null,
          hitColor: '#5e6a8a'
        },
        {
          id: 'B',
          positions: [3, 6],
          image: null,
          hitColor: '#8792ad'
        },
        {
          id: 'C',
          positions: [2, 5],
          image: null,
          hitColor: '#B8BFD1'
        }
      ]
    },
    modelID: 'D1',
    meshName: 'Canopy'
  },
  innerCanopy: {
    imageURL: null,
    layout: {
      numberOfSidedPolygon: 6,
      noPolygon: true,
      sides: [
        {
          id: null,
          positions: [],
          image: null,
          hitColor: '#B8BFD1',
          designs: []
        }
      ]
    },
    designs: [],
    modelID: 'D1',
    meshName: 'Inner_canopy'
  },
  belt: {
    imageURL: null,
    layout: {
      numberOfSidedPolygon: 6,
      noPolygon: true,
      sides: [
        {
          id: null,
          positions: [],
          image: null,
          hitColor: '#B8BFD1',
          designs: []
        }
      ]
    },
    designs: [],
    modelID: 'D1',
    meshName: 'Belt1'
  },
  cover: {
    imageURL: null,
    layout: {
      numberOfSidedPolygon: 6,
      noPolygon: true,
      sides: [
        {
          id: null,
          positions: [],
          image: null,
          hitColor: '#B8BFD1',
          designs: []
        }
      ]
    },
    designs: [],
    modelID: 'D3',
    meshName: 'Sleeve'
  }
}

const layout_6k_A6 = {
  layoutType: '6k',
  canopy: {
    imageURL: '',
    layout: {
      numberOfSidedPolygon: 6,
      noPolygon: false,
      sides: [
        {
          id: 'A',
          positions: [1, 4],
          image: null,
          hitColor: '#8792ad'
        },
        {
          id: 'B',
          positions: [2, 3, 5, 6],
          image: null,
          hitColor: '#b8bfd1'
        }
      ]
    },
    modelID: 'D1',
    meshName: 'Canopy'
  },
  innerCanopy: {
    imageURL: null,
    layout: {
      numberOfSidedPolygon: 6,
      noPolygon: true,
      sides: [
        {
          id: null,
          positions: [],
          image: null,
          hitColor: '#B8BFD1',
          designs: []
        }
      ]
    },
    designs: [],
    modelID: 'D1',
    meshName: 'Inner_canopy'
  },
  belt: {
    imageURL: null,
    layout: {
      numberOfSidedPolygon: 6,
      noPolygon: true,
      sides: [
        {
          id: null,
          positions: [],
          image: null,
          hitColor: '#B8BFD1',
          designs: []
        }
      ]
    },
    designs: [],
    modelID: 'D1',
    meshName: 'Belt1'
  },
  cover: {
    imageURL: null,
    layout: {
      numberOfSidedPolygon: 6,
      noPolygon: true,
      sides: [
        {
          id: null,
          positions: [],
          image: null,
          hitColor: '#B8BFD1',
          designs: []
        }
      ]
    },
    designs: [],
    modelID: 'D3',
    meshName: 'Sleeve'
  }
}

const layout_7k_A1 = {
  layoutType: '7k',
  canopy: {
    imageURL: '',
    layout: {
      numberOfSidedPolygon: 7,
      noPolygon: true,
      sides: [
        {
          id: null,
          positions: [],
          image: null,
          hitColor: '#B8BFD1'
        }
      ]
    },
    modelID: 'B1F',
    meshName: 'Canopy'
  },
  innerCanopy: {
    imageURL: null,
    designs: [],
    layout: {
      numberOfSidedPolygon: 7,
      noPolygon: true,
      sides: [
        {
          id: null,
          positions: [],
          image: null,
          hitColor: '#B8BFD1',
          designs: []
        }
      ]
    },
    modelID: 'B1F',
    meshName: 'Inner_canopy'
  },
  belt: {
    imageURL: null,
    designs: [],
    layout: {
      numberOfSidedPolygon: 7,
      noPolygon: true,
      sides: [
        {
          id: null,
          positions: [],
          image: null,
          hitColor: '#B8BFD1',
          designs: []
        }
      ]
    },
    modelID: 'B1F',
    meshName: 'Belt1'
  },
  cover: {
    imageURL: null,
    designs: [],
    layout: {
      numberOfSidedPolygon: 7,
      noPolygon: true,
      sides: [
        {
          id: null,
          positions: [],
          image: null,
          hitColor: '#B8BFD1',
          designs: []
        }
      ]
    },
    modelID: 'B3',
    meshName: 'Sleeve'
  }
}

const layout_7k_A2 = {
  layoutType: '7k',
  canopy: {
    imageURL: '',
    layout: {
      numberOfSidedPolygon: 7,
      noPolygon: false,
      sides: [
        {
          id: 'A',
          positions: [1, 2, 3, 4, 5, 6, 7],
          image: null,
          hitColor: '#B8BFD1'
        }
      ]
    },
    modelID: 'B1',
    meshName: 'Canopy'
  },
  innerCanopy: {
    imageURL: null,
    designs: [],
    layout: {
      numberOfSidedPolygon: 7,
      noPolygon: true,
      sides: [
        {
          id: null,
          positions: [],
          image: null,
          hitColor: '#B8BFD1',
          designs: []
        }
      ]
    },
    modelID: 'B1',
    meshName: 'Inner_canopy'
  },
  belt: {
    imageURL: null,
    designs: [],
    layout: {
      numberOfSidedPolygon: 7,
      noPolygon: true,
      sides: [
        {
          id: null,
          positions: [],
          image: null,
          hitColor: '#B8BFD1',
          designs: []
        }
      ]
    },
    modelID: 'B1',
    meshName: 'Belt1'
  },
  cover: {
    imageURL: null,
    designs: [],
    layout: {
      numberOfSidedPolygon: 7,
      noPolygon: true,
      sides: [
        {
          id: null,
          positions: [],
          image: null,
          hitColor: '#B8BFD1',
          designs: []
        }
      ]
    },
    modelID: 'B3',
    meshName: 'Sleeve'
  }
}

const layout_7k_A3 = {
  layoutType: '7k',
  canopy: {
    imageURL: '',
    layout: {
      numberOfSidedPolygon: 7,
      noPolygon: false,
      sides: [
        {
          id: 'A',
          positions: [1, 4, 6],
          image: null,
          hitColor: '#5E6A8A'
        },
        {
          id: 'B',
          positions: [3, 5, 7],
          image: null,
          hitColor: '#8792AD'
        },
        {
          id: 'C',
          positions: [2],
          image: null,
          hitColor: '#B8BFD1'
        }
      ]
    },
    modelID: 'B1',
    meshName: 'Canopy'
  },
  innerCanopy: {
    imageURL: null,
    designs: [],
    layout: {
      numberOfSidedPolygon: 7,
      noPolygon: true,
      sides: [
        {
          id: null,
          positions: [],
          image: null,
          hitColor: '#B8BFD1',
          designs: []
        }
      ]
    },
    modelID: 'B1',
    meshName: 'Inner_canopy'
  },
  belt: {
    imageURL: null,
    designs: [],
    layout: {
      numberOfSidedPolygon: 7,
      noPolygon: true,
      sides: [
        {
          id: null,
          positions: [],
          image: null,
          hitColor: '#B8BFD1',
          designs: []
        }
      ]
    },
    modelID: 'B1',
    meshName: 'Belt1'
  },
  cover: {
    imageURL: null,
    designs: [],
    layout: {
      numberOfSidedPolygon: 7,
      noPolygon: true,
      sides: [
        {
          id: null,
          positions: [],
          image: null,
          hitColor: '#B8BFD1',
          designs: []
        }
      ]
    },
    modelID: 'B3',
    meshName: 'Sleeve'
  }
}

const layout_7k_A4 = {
  layoutType: '7k',
  canopy: {
    imageURL: '',
    layout: {
      numberOfSidedPolygon: 7,
      noPolygon: false,
      sides: [
        {
          id: 'A',
          positions: [1],
          image: null,
          hitColor: '#B8BFD1'
        },
        {
          id: 'B',
          positions: [7],
          image: null,
          hitColor: '#B8BFD2'
        },
        {
          id: 'C',
          positions: [6],
          image: null,
          hitColor: '#B8BFD3'
        },
        {
          id: 'D',
          positions: [5],
          image: null,
          hitColor: '#B8BFD4'
        },
        {
          id: 'E',
          positions: [4],
          image: null,
          hitColor: '#B8BFD5'
        },
        {
          id: 'F',
          positions: [3],
          image: null,
          hitColor: '#B8BFD6'
        },
        {
          id: 'G',
          positions: [2],
          image: null,
          hitColor: '#B8BFD7'
        }
      ]
    },
    modelID: 'B1',
    meshName: 'Canopy'
  },
  innerCanopy: {
    imageURL: null,
    designs: [],
    layout: {
      numberOfSidedPolygon: 7,
      noPolygon: true,
      sides: [
        {
          id: null,
          positions: [],
          image: null,
          hitColor: '#B8BFD1',
          designs: []
        }
      ]
    },
    modelID: 'B1',
    meshName: 'Inner_canopy'
  },
  belt: {
    imageURL: null,
    designs: [],
    layout: {
      numberOfSidedPolygon: 7,
      noPolygon: true,
      sides: [
        {
          id: null,
          positions: [],
          image: null,
          hitColor: '#B8BFD1',
          designs: []
        }
      ]
    },
    modelID: 'B1',
    meshName: 'Belt1'
  },
  cover: {
    imageURL: null,
    designs: [],
    layout: {
      numberOfSidedPolygon: 7,
      noPolygon: true,
      sides: [
        {
          id: null,
          positions: [],
          image: null,
          hitColor: '#B8BFD1',
          designs: []
        }
      ]
    },
    modelID: 'B3',
    meshName: 'Sleeve'
  }
}

const layout_7k_A5 = {
  layoutType: '7k',
  canopy: {
    imageURL: '',
    layout: {
      numberOfSidedPolygon: 7,
      noPolygon: false,
      sides: [
        {
          id: 'A',
          positions: [1, 3, 6],
          image: null,
          hitColor: '#5e6a8a'
        },
        {
          id: 'B',
          positions: [2, 4, 5, 7],
          image: null,
          hitColor: '#8792ad'
        }
      ]
    },
    modelID: 'B1',
    meshName: 'Canopy'
  },
  innerCanopy: {
    imageURL: null,
    designs: [],
    layout: {
      numberOfSidedPolygon: 7,
      noPolygon: true,
      sides: [
        {
          id: null,
          positions: [],
          image: null,
          hitColor: '#B8BFD1',
          designs: []
        }
      ]
    },
    modelID: 'B1',
    meshName: 'Inner_canopy'
  },
  belt: {
    imageURL: null,
    designs: [],
    layout: {
      numberOfSidedPolygon: 7,
      noPolygon: true,
      sides: [
        {
          id: null,
          positions: [],
          image: null,
          hitColor: '#B8BFD1',
          designs: []
        }
      ]
    },
    modelID: 'B1',
    meshName: 'Belt1'
  },
  cover: {
    imageURL: null,
    designs: [],
    layout: {
      numberOfSidedPolygon: 7,
      noPolygon: true,
      sides: [
        {
          id: null,
          positions: [],
          image: null,
          hitColor: '#B8BFD1',
          designs: []
        }
      ]
    },
    modelID: 'B3',
    meshName: 'Sleeve'
  }
}

const layout_7k_A6 = {
  layoutType: '7k',
  canopy: {
    imageURL: '',
    layout: {
      numberOfSidedPolygon: 7,
      noPolygon: false,
      sides: [
        {
          id: 'A',
          positions: [1],
          image: null,
          hitColor: '#8792ad'
        },
        {
          id: 'B',
          positions: [2, 3, 4, 5, 6, 7],
          image: null,
          hitColor: '#b8bfd1'
        }
      ]
    },
    modelID: 'B1',
    meshName: 'Canopy'
  },
  innerCanopy: {
    imageURL: null,
    designs: [],
    layout: {
      numberOfSidedPolygon: 7,
      noPolygon: true,
      sides: [
        {
          id: null,
          positions: [],
          image: null,
          hitColor: '#B8BFD1',
          designs: []
        }
      ]
    },
    modelID: 'B1',
    meshName: 'Inner_canopy'
  },
  belt: {
    imageURL: null,
    designs: [],
    layout: {
      numberOfSidedPolygon: 7,
      noPolygon: true,
      sides: [
        {
          id: null,
          positions: [],
          image: null,
          hitColor: '#B8BFD1',
          designs: []
        }
      ]
    },
    modelID: 'B1',
    meshName: 'Belt1'
  },
  cover: {
    imageURL: null,
    designs: [],
    layout: {
      numberOfSidedPolygon: 7,
      noPolygon: true,
      sides: [
        {
          id: null,
          positions: [],
          image: null,
          hitColor: '#B8BFD1',
          designs: []
        }
      ]
    },
    modelID: 'B3',
    meshName: 'Sleeve'
  }
}

const layout_8k_23_A1 = {
  layoutType: '8k_23',
  canopy: {
    imageURL: '',
    layout: {
      numberOfSidedPolygon: 8,
      noPolygon: true,
      sides: [
        {
          id: null,
          positions: [],
          image: null,
          hitColor: '#B8BFD1'
        }
      ]
    },
    modelID: 'C1',
    meshName: 'Canopy'
  },
  innerCanopy: {
    imageURL: null,
    designs: [],
    layout: {
      numberOfSidedPolygon: 8,
      noPolygon: true,
      sides: [
        {
          id: null,
          positions: [],
          image: null,
          hitColor: '#B8BFD1',
          designs: []
        }
      ]
    },
    modelID: 'C1',
    meshName: 'Inner_canopy'
  },
  belt: {
    imageURL: null,
    designs: [],
    layout: {
      numberOfSidedPolygon: 8,
      noPolygon: true,
      sides: [
        {
          id: null,
          positions: [],
          image: null,
          hitColor: '#B8BFD1',
          designs: []
        }
      ]
    },
    modelID: 'C1',
    meshName: 'Belt1'
  },
  cover: {
    imageURL: null,
    designs: [],
    layout: {
      numberOfSidedPolygon: 8,
      noPolygon: true,
      sides: [
        {
          id: null,
          positions: [],
          image: null,
          hitColor: '#B8BFD1',
          designs: []
        }
      ]
    },
    modelID: 'C3',
    meshName: 'Sleeve'
  }
}

const layout_8k_23_A2 = {
  layoutType: '8k_23',
  canopy: {
    imageURL: '',
    layout: {
      numberOfSidedPolygon: 8,
      noPolygon: false,
      sides: [
        {
          id: 'A',
          positions: [1, 2, 3, 4, 5, 6, 7, 8],
          image: null,
          hitColor: '#5E6A8A'
        }
      ]
    },
    modelID: 'C1',
    meshName: 'Canopy'
  },
  innerCanopy: {
    imageURL: null,
    designs: [],
    layout: {
      numberOfSidedPolygon: 8,
      noPolygon: true,
      sides: [
        {
          id: null,
          positions: [],
          image: null,
          hitColor: '#B8BFD1',
          designs: []
        }
      ]
    },
    modelID: 'C1',
    meshName: 'Inner_canopy'
  },
  belt: {
    imageURL: null,
    designs: [],
    layout: {
      numberOfSidedPolygon: 8,
      noPolygon: true,
      sides: [
        {
          id: null,
          positions: [],
          image: null,
          hitColor: '#B8BFD1',
          designs: []
        }
      ]
    },
    modelID: 'C1',
    meshName: 'Belt1'
  },
  cover: {
    imageURL: null,
    designs: [],
    layout: {
      numberOfSidedPolygon: 8,
      noPolygon: true,
      sides: [
        {
          id: null,
          positions: [],
          image: null,
          hitColor: '#B8BFD1',
          designs: []
        }
      ]
    },
    modelID: 'C3',
    meshName: 'Sleeve'
  }
}

const layout_8k_23_A3 = {
  layoutType: '8k_23',
  canopy: {
    imageURL: '',
    layout: {
      numberOfSidedPolygon: 8,
      noPolygon: false,
      sides: [
        {
          id: 'A',
          positions: [1, 3, 5, 7],
          image: null,
          hitColor: '#5E6A8A'
        },
        {
          id: 'B',
          positions: [2, 4, 6, 8],
          image: null,
          hitColor: '#B8BFD1'
        }
      ]
    },
    modelID: 'C1',
    meshName: 'Canopy'
  },
  innerCanopy: {
    imageURL: null,
    designs: [],
    layout: {
      numberOfSidedPolygon: 8,
      noPolygon: true,
      sides: [
        {
          id: null,
          positions: [],
          image: null,
          hitColor: '#B8BFD1',
          designs: []
        }
      ]
    },
    modelID: 'C1',
    meshName: 'Inner_canopy'
  },
  belt: {
    imageURL: null,
    designs: [],
    layout: {
      numberOfSidedPolygon: 8,
      noPolygon: true,
      sides: [
        {
          id: null,
          positions: [],
          image: null,
          hitColor: '#B8BFD1',
          designs: []
        }
      ]
    },
    modelID: 'C1',
    meshName: 'Belt1'
  },
  cover: {
    imageURL: null,
    designs: [],
    layout: {
      numberOfSidedPolygon: 8,
      noPolygon: true,
      sides: [
        {
          id: null,
          positions: [],
          image: null,
          hitColor: '#B8BFD1',
          designs: []
        }
      ]
    },
    modelID: 'C3',
    meshName: 'Sleeve'
  }
}

const layout_8k_23_A4 = {
  layoutType: '8k_23',
  canopy: {
    imageURL: '',
    layout: {
      numberOfSidedPolygon: 8,
      noPolygon: false,
      sides: [
        {
          id: 'A',
          positions: [1],
          image: null,
          hitColor: '#B8BFD1'
        },
        {
          id: 'B',
          positions: [8],
          image: null,
          hitColor: '#B8BFD2'
        },
        {
          id: 'C',
          positions: [7],
          image: null,
          hitColor: '#B8BFD3'
        },
        {
          id: 'D',
          positions: [6],
          image: null,
          hitColor: '#B8BFD4'
        },
        {
          id: 'E',
          positions: [5],
          image: null,
          hitColor: '#B8BFD5'
        },
        {
          id: 'F',
          positions: [4],
          image: null,
          hitColor: '#B8BFD6'
        },
        {
          id: 'G',
          positions: [3],
          image: null,
          hitColor: '#B8BFD7'
        },
        {
          id: 'H',
          positions: [2],
          image: null,
          hitColor: '#B8BFD8'
        }
      ]
    },
    modelID: 'C1',
    meshName: 'Canopy'
  },
  innerCanopy: {
    imageURL: null,
    designs: [],
    layout: {
      numberOfSidedPolygon: 8,
      noPolygon: true,
      sides: [
        {
          id: null,
          positions: [],
          image: null,
          hitColor: '#B8BFD1',
          designs: []
        }
      ]
    },
    modelID: 'C1',
    meshName: 'Inner_canopy'
  },
  belt: {
    imageURL: null,
    designs: [],
    layout: {
      numberOfSidedPolygon: 8,
      noPolygon: true,
      sides: [
        {
          id: null,
          positions: [],
          image: null,
          hitColor: '#B8BFD1',
          designs: []
        }
      ]
    },
    modelID: 'C1',
    meshName: 'Belt1'
  },
  cover: {
    imageURL: null,
    designs: [],
    layout: {
      numberOfSidedPolygon: 8,
      noPolygon: true,
      sides: [
        {
          id: null,
          positions: [],
          image: null,
          hitColor: '#B8BFD1',
          designs: []
        }
      ]
    },
    modelID: 'C3',
    meshName: 'Sleeve'
  }
}

const layout_8k_23_A5 = {
  layoutType: '8k_23',
  canopy: {
    imageURL: '',
    layout: {
      numberOfSidedPolygon: 8,
      noPolygon: false,
      sides: [
        {
          id: 'A',
          positions: [1, 5],
          image: null,
          hitColor: '#3B4766'
        },
        {
          id: 'B',
          positions: [4, 8],
          image: null,
          hitColor: '#5e6a8d'
        },
        {
          id: 'C',
          positions: [3, 7],
          image: null,
          hitColor: '#8792ad'
        },
        {
          id: 'D',
          positions: [2, 6],
          image: null,
          hitColor: '#B8BFD1'
        }
      ]
    },
    modelID: 'C1',
    meshName: 'Canopy'
  },
  innerCanopy: {
    imageURL: null,
    designs: [],
    layout: {
      numberOfSidedPolygon: 8,
      noPolygon: true,
      sides: [
        {
          id: null,
          positions: [],
          image: null,
          hitColor: '#B8BFD1',
          designs: []
        }
      ]
    },
    modelID: 'C1',
    meshName: 'Inner_canopy'
  },
  belt: {
    imageURL: null,
    designs: [],
    layout: {
      numberOfSidedPolygon: 8,
      noPolygon: true,
      sides: [
        {
          id: null,
          positions: [],
          image: null,
          hitColor: '#B8BFD1',
          designs: []
        }
      ]
    },
    modelID: 'C1',
    meshName: 'Belt1'
  },
  cover: {
    imageURL: null,
    designs: [],
    layout: {
      numberOfSidedPolygon: 8,
      noPolygon: true,
      sides: [
        {
          id: null,
          positions: [],
          image: null,
          hitColor: '#B8BFD1',
          designs: []
        }
      ]
    },
    modelID: 'C3',
    meshName: 'Sleeve'
  }
}

const layout_8k_23_A6 = {
  layoutType: '8k_23',
  canopy: {
    imageURL: '',
    layout: {
      numberOfSidedPolygon: 8,
      noPolygon: false,
      sides: [
        {
          id: 'A',
          positions: [1, 5],
          image: null,
          hitColor: '#8792ad'
        },
        {
          id: 'B',
          positions: [2, 3, 4, 6, 7, 8],
          image: null,
          hitColor: '#b8bfd1'
        }
      ]
    },
    modelID: 'C1',
    meshName: 'Canopy'
  },
  innerCanopy: {
    imageURL: null,
    designs: [],
    layout: {
      numberOfSidedPolygon: 8,
      noPolygon: true,
      sides: [
        {
          id: null,
          positions: [],
          image: null,
          hitColor: '#B8BFD1',
          designs: []
        }
      ]
    },
    modelID: 'C1',
    meshName: 'Inner_canopy'
  },
  belt: {
    imageURL: null,
    designs: [],
    layout: {
      numberOfSidedPolygon: 8,
      noPolygon: true,
      sides: [
        {
          id: null,
          positions: [],
          image: null,
          hitColor: '#B8BFD1',
          designs: []
        }
      ]
    },
    modelID: 'C1',
    meshName: 'Belt1'
  },
  cover: {
    imageURL: null,
    designs: [],
    layout: {
      numberOfSidedPolygon: 8,
      noPolygon: true,
      sides: [
        {
          id: null,
          positions: [],
          image: null,
          hitColor: '#B8BFD1',
          designs: []
        }
      ]
    },
    modelID: 'C3',
    meshName: 'Sleeve'
  }
}

const layout_8k_27_A1 = {
  layoutType: '8k_27',
  canopy: {
    imageURL: '',
    layout: {
      numberOfSidedPolygon: 8,
      noPolygon: true,
      sides: [
        {
          id: null,
          positions: [],
          image: null,
          hitColor: '#B8BFD1'
        }
      ]
    },
    modelID: 'A1',
    meshName: 'Canopy'
  },
  innerCanopy: {
    imageURL: null,
    designs: [],
    layout: {
      numberOfSidedPolygon: 8,
      noPolygon: true,
      sides: [
        {
          id: null,
          positions: [],
          image: null,
          hitColor: '#B8BFD1',
          designs: []
        }
      ]
    },
    modelID: 'A1',
    meshName: 'Inner_canopy'
  },
  belt: {
    imageURL: null,
    designs: [],
    layout: {
      numberOfSidedPolygon: 8,
      noPolygon: true,
      sides: [
        {
          id: null,
          positions: [],
          image: null,
          hitColor: '#B8BFD1',
          designs: []
        }
      ]
    },
    modelID: 'A1',
    meshName: 'Belt1'
  },
  cover: {
    imageURL: null,
    designs: [],
    layout: {
      numberOfSidedPolygon: 8,
      noPolygon: true,
      sides: [
        {
          id: null,
          positions: [],
          image: null,
          hitColor: '#B8BFD1',
          designs: []
        }
      ]
    },
    modelID: 'A3',
    meshName: 'Sleeve'
  }
}

const layout_8k_27_A2 = {
  layoutType: '8k_27',
  canopy: {
    imageURL: '',
    layout: {
      numberOfSidedPolygon: 8,
      noPolygon: false,
      sides: [
        {
          id: 'A',
          positions: [1, 2, 3, 4, 5, 6, 7, 8],
          image: null,
          hitColor: '#5E6A8A'
        }
      ]
    },
    modelID: 'A1',
    meshName: 'Canopy'
  },
  innerCanopy: {
    imageURL: null,
    designs: [],
    layout: {
      numberOfSidedPolygon: 8,
      noPolygon: true,
      sides: [
        {
          id: null,
          positions: [],
          image: null,
          hitColor: '#B8BFD1',
          designs: []
        }
      ]
    },
    modelID: 'A1',
    meshName: 'Inner_canopy'
  },
  belt: {
    imageURL: null,
    designs: [],
    layout: {
      numberOfSidedPolygon: 8,
      noPolygon: true,
      sides: [
        {
          id: null,
          positions: [],
          image: null,
          hitColor: '#B8BFD1',
          designs: []
        }
      ]
    },
    modelID: 'A1',
    meshName: 'Belt1'
  },
  cover: {
    imageURL: null,
    designs: [],
    layout: {
      numberOfSidedPolygon: 8,
      noPolygon: true,
      sides: [
        {
          id: null,
          positions: [],
          image: null,
          hitColor: '#B8BFD1',
          designs: []
        }
      ]
    },
    modelID: 'A3',
    meshName: 'Sleeve'
  }
}

const layout_8k_27_A3 = {
  layoutType: '8k_27',
  canopy: {
    imageURL: '',
    layout: {
      numberOfSidedPolygon: 8,
      noPolygon: false,
      sides: [
        {
          id: 'A',
          positions: [1, 3, 5, 7],
          image: null,
          hitColor: '#5E6A8A'
        },
        {
          id: 'B',
          positions: [2, 4, 6, 8],
          image: null,
          hitColor: '#B8BFD1'
        }
      ]
    },
    modelID: 'A1',
    meshName: 'Canopy'
  },
  innerCanopy: {
    imageURL: null,
    designs: [],
    layout: {
      numberOfSidedPolygon: 8,
      noPolygon: true,
      sides: [
        {
          id: null,
          positions: [],
          image: null,
          hitColor: '#B8BFD1',
          designs: []
        }
      ]
    },
    modelID: 'A1',
    meshName: 'Inner_canopy'
  },
  belt: {
    imageURL: null,
    designs: [],
    layout: {
      numberOfSidedPolygon: 8,
      noPolygon: true,
      sides: [
        {
          id: null,
          positions: [],
          image: null,
          hitColor: '#B8BFD1',
          designs: []
        }
      ]
    },
    modelID: 'A1',
    meshName: 'Belt1'
  },
  cover: {
    imageURL: null,
    designs: [],
    layout: {
      numberOfSidedPolygon: 8,
      noPolygon: true,
      sides: [
        {
          id: null,
          positions: [],
          image: null,
          hitColor: '#B8BFD1',
          designs: []
        }
      ]
    },
    modelID: 'A3',
    meshName: 'Sleeve'
  }
}

const layout_8k_27_A4 = {
  layoutType: '8k_27',
  canopy: {
    imageURL: '',
    layout: {
      numberOfSidedPolygon: 8,
      noPolygon: false,
      sides: [
        {
          id: 'A',
          positions: [1],
          image: null,
          hitColor: '#B8BFD1'
        },
        {
          id: 'B',
          positions: [8],
          image: null,
          hitColor: '#B8BFD2'
        },
        {
          id: 'C',
          positions: [7],
          image: null,
          hitColor: '#B8BFD3'
        },
        {
          id: 'D',
          positions: [6],
          image: null,
          hitColor: '#B8BFD4'
        },
        {
          id: 'E',
          positions: [5],
          image: null,
          hitColor: '#B8BFD5'
        },
        {
          id: 'F',
          positions: [4],
          image: null,
          hitColor: '#B8BFD6'
        },
        {
          id: 'G',
          positions: [3],
          image: null,
          hitColor: '#B8BFD7'
        },
        {
          id: 'H',
          positions: [2],
          image: null,
          hitColor: '#B8BFD8'
        }
      ]
    },
    modelID: 'A1',
    meshName: 'Canopy'
  },
  innerCanopy: {
    imageURL: null,
    designs: [],
    layout: {
      numberOfSidedPolygon: 8,
      noPolygon: true,
      sides: [
        {
          id: null,
          positions: [],
          image: null,
          hitColor: '#B8BFD1',
          designs: []
        }
      ]
    },
    modelID: 'A1',
    meshName: 'Inner_canopy'
  },
  belt: {
    imageURL: null,
    designs: [],
    layout: {
      numberOfSidedPolygon: 8,
      noPolygon: true,
      sides: [
        {
          id: null,
          positions: [],
          image: null,
          hitColor: '#B8BFD1',
          designs: []
        }
      ]
    },
    modelID: 'A1',
    meshName: 'Belt1'
  },
  cover: {
    imageURL: null,
    designs: [],
    layout: {
      numberOfSidedPolygon: 8,
      noPolygon: true,
      sides: [
        {
          id: null,
          positions: [],
          image: null,
          hitColor: '#B8BFD1',
          designs: []
        }
      ]
    },
    modelID: 'A3',
    meshName: 'Sleeve'
  }
}

const layout_8k_27_A5 = {
  layoutType: '8k_27',
  canopy: {
    imageURL: '',
    layout: {
      numberOfSidedPolygon: 8,
      noPolygon: false,
      sides: [
        {
          id: 'A',
          positions: [1, 5],
          image: null,
          hitColor: '#3B4766'
        },
        {
          id: 'B',
          positions: [4, 8],
          image: null,
          hitColor: '#5e6a8d'
        },
        {
          id: 'C',
          positions: [3, 7],
          image: null,
          hitColor: '#8792ad'
        },
        {
          id: 'D',
          positions: [2, 6],
          image: null,
          hitColor: '#B8BFD1'
        }
      ]
    },
    modelID: 'A1',
    meshName: 'Canopy'
  },
  innerCanopy: {
    imageURL: null,
    designs: [],
    layout: {
      numberOfSidedPolygon: 8,
      noPolygon: true,
      sides: [
        {
          id: null,
          positions: [],
          image: null,
          hitColor: '#B8BFD1',
          designs: []
        }
      ]
    },
    modelID: 'A1',
    meshName: 'Inner_canopy'
  },
  belt: {
    imageURL: null,
    designs: [],
    layout: {
      numberOfSidedPolygon: 8,
      noPolygon: true,
      sides: [
        {
          id: null,
          positions: [],
          image: null,
          hitColor: '#B8BFD1',
          designs: []
        }
      ]
    },
    modelID: 'A1',
    meshName: 'Belt1'
  },
  cover: {
    imageURL: null,
    designs: [],
    layout: {
      numberOfSidedPolygon: 8,
      noPolygon: true,
      sides: [
        {
          id: null,
          positions: [],
          image: null,
          hitColor: '#B8BFD1',
          designs: []
        }
      ]
    },
    modelID: 'A3',
    meshName: 'Sleeve'
  }
}

const layout_8k_27_A6 = {
  layoutType: '8k_27',
  canopy: {
    imageURL: '',
    layout: {
      numberOfSidedPolygon: 8,
      noPolygon: false,
      sides: [
        {
          id: 'A',
          positions: [1, 5],
          image: null,
          hitColor: '#8792ad'
        },
        {
          id: 'B',
          positions: [2, 3, 4, 6, 7, 8],
          image: null,
          hitColor: '#b8bfd1'
        }
      ]
    },
    modelID: 'A1',
    meshName: 'Canopy'
  },
  innerCanopy: {
    imageURL: null,
    designs: [],
    layout: {
      numberOfSidedPolygon: 8,
      noPolygon: true,
      sides: [
        {
          id: null,
          positions: [],
          image: null,
          hitColor: '#B8BFD1',
          designs: []
        }
      ]
    },
    modelID: 'A1',
    meshName: 'Inner_canopy'
  },
  belt: {
    imageURL: null,
    designs: [],
    layout: {
      numberOfSidedPolygon: 8,
      noPolygon: true,
      sides: [
        {
          id: null,
          positions: [],
          image: null,
          hitColor: '#B8BFD1',
          designs: []
        }
      ]
    },
    modelID: 'A1',
    meshName: 'Belt1'
  },
  cover: {
    imageURL: null,
    designs: [],
    layout: {
      numberOfSidedPolygon: 8,
      noPolygon: true,
      sides: [
        {
          id: null,
          positions: [],
          image: null,
          hitColor: '#B8BFD1',
          designs: []
        }
      ]
    },
    modelID: 'A3',
    meshName: 'Sleeve'
  }
}

export {
  layout_6k_A1,
  layout_6k_A2,
  layout_6k_A3,
  layout_6k_A4,
  layout_6k_A5,
  layout_6k_A6,
  layout_7k_A1,
  layout_7k_A2,
  layout_7k_A3,
  layout_7k_A4,
  layout_7k_A5,
  layout_7k_A6,
  layout_8k_23_A1,
  layout_8k_23_A2,
  layout_8k_23_A3,
  layout_8k_23_A4,
  layout_8k_23_A5,
  layout_8k_23_A6,
  layout_8k_27_A1,
  layout_8k_27_A2,
  layout_8k_27_A3,
  layout_8k_27_A4,
  layout_8k_27_A5,
  layout_8k_27_A6
}
