
import { defineComponent, computed } from 'vue'
import { useRoute } from 'vue-router'
import { getRefreshToken } from '@/library/axios/api'
import { getTime } from 'date-fns'
import { isString } from 'lodash'
import { IPutAuthTokenResponse } from '@/types'

export default defineComponent({
  setup() {
    const route = useRoute()
    const layout = computed(() => route.meta.layout)
    let timer: any = null

    const refreshToken = async () => {
      const refreshToken = localStorage.getItem('refreshToken')

      if (isString(refreshToken)) {
        const data: IPutAuthTokenResponse = await getRefreshToken({
          refreshToken
        })
        const { accessToken, accessTokenExp } = data
        localStorage.setItem('accessToken', accessToken)
        localStorage.setItem('accessTokenExp', accessTokenExp.toString())
        localStorage.setItem('refreshTokenExp', getTime(new Date()).toString())
      }
    }

    const setTimer = () => {
      timer = setInterval(refreshToken, 1000 * 60 * 5)
    }

    const initialize = () => {
      setTimer()
    }
    initialize()
    return {
      layout
    }
  }
})
